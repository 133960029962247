import React from "react"

function BasicPage({title, children}){
    return(
        <div className="container">
            <div className="basic-page">
                {title ? <h1>{title}</h1> : null}
                {children}
            </div>
            <style jsx global>{`
                .basic-page h1{
                    font-size: 48px;
                    margin-top:30px;
                    margin-bottom: 60px;
                    color: #000;
                }
                .basic-page h2{
                    font-size: 28px;
                    margin-top: 30px;
                    margin-bottom: 15px;
                    color: #000;
                }
                .basic-page ol,
                .basic-page ul,
                .basic-page p{
                    margin-bottom:15px;
                }
                .basic-page a{
                    color:var(--main-color);
                }
                .basic-page ul li{
                    color: rgb(102, 102, 102);
                    font-size: 18px;
                    line-height: 30px;
                    position:relative;
                    padding-left:30px;
                }
                .basic-page ul li:before{
                    content:"+";
                    position:absolute;
                    left:0;
                }
                .basic-page ol{
                    padding-left:0;
                }
                .basic-page ol li{
                    color: rgb(102, 102, 102);
                    font-size: 18px;
                    line-height: 30px;
                }
                .basic-page table td,
                .basic-page table th{
                    color: rgb(102, 102, 102);
                    font-size: 16px;
                    line-height: 24px;
                    padding:5px;
                    border:1px solid #efefef;
                }
            `}</style>
        </div>
    )
}

export default BasicPage